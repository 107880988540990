const sizeAndSpace = {
  none: 0,
  xxsmall: 2,
  xsmall: 4,
  small: 8,
  medium: 12,
  large: 16,
  xlarge: 24,
  xxlarge: 32,
  xxxlarge: 48,
  gigantic: 64,
  xgigantic: 128,
  xxgigantic: 256,
  xxxgigantic: 512,
}

// see https://styled-system.com
const theme = {
  breakpoints: ['576px', '768px', '992px', '1200px', '1440px'],

  // these are in px
  space: sizeAndSpace,
  sizes: sizeAndSpace,
  radii: { none: 0, xsmall: 3, small: 6, medium: 12, large: 16, xlarge: 20, full: 9999 },
  borderWidths: { none: 0, xthin: 1, thin: 2, thick: 4, xthick: 6, xxthick: 8 },
  borders: {
    none: 'none',
    light: '1px solid #B4B9C4',
  },
  shadows: {
    none: 'none',
    low: '1px 2px rgba(0, 0, 0, .4)',
  },

  fontSizes: { small: '11px', medium: '13px', large: '16px', xlarge: '24px' },
  fontWeights: { light: 300, medium: 400, bold: 600 },
  lineHeights: {
    xsmall: '13px',
    small: '18px',
    medium: '18px',
    large: '24px',
    xlarge: '24px',
    xxlarge: '32px',
  },
  iconSizes: {
    inherit: 'inherit',
    xsmall: '14px',
    small: '18px',
    medium: '24px',
    large: '32px',
    xlarge: '40px',
    xxlarge: '48px',
    gigantic: '80px',
  },

  colors: {
    background: ['#000000', '#313131'],
    foreground: ['#D1B971'],
    primary: ['#D1B971'],
    neutral: ['#787F8F', '#6F7585', '#B4B9C4', '#ECEFF1', '#F6F9FB', '#FFFFFF'],
  },
}

// aliases
Object.assign(theme.breakpoints, {
  small: theme.breakpoints[0],
  medium: theme.breakpoints[1],
  large: theme.breakpoints[2],
  xlarge: theme.breakpoints[3],
  xxlarge: theme.breakpoints[4],
})

export default theme
