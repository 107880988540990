import { createGlobalStyle } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

export default createGlobalStyle`
  html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    background: ${themeGet('colors.background.1')};
  }

  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Lexend Tera', sans-serif;
  }
`
